jQuery(function ($) {
  svg4everybody();
  objectFitImages();

  var iOSMatch = navigator.appVersion.match(/iPhone/) || navigator.appVersion.match(/iPad/);
  var versionMatch = navigator.appVersion.match(/iPhone OS ([0-9_]+) /);
  var version = versionMatch && versionMatch.length ? versionMatch.pop().split('_')[0] : false;
  var IEVersion = detectIE();
  var isIEandNotEdge = IEVersion && IEVersion < 12;
  var isEdge = IEVersion && IEVersion >= 12;

  if (iOSMatch === 'iPhone OS' && version !== '10') {
    document.write('<meta http-equiv="Content-Security-Policy" content="default-src * \'unsafe-inline\' \'unsafe-eval\' data: blob:;">');
  }

  if (isIEandNotEdge) {
    $('html').attr('data-is-ie', IEVersion);
  } else if (isEdge) {
    $('html').attr('data-is-edge', IEVersion);
  }

  if (!(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)) {
    $('html').scrollWithEase();
  }

  var $slickSlider = $('[data-slick-slider]').slick({
    dots: true,
    speed: 500,
    slidesToShow: 4,
    autoplay: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  });

  var $slickBanner = $('[data-slick-banner]').slick({
    dots: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  });

  $('[data-slick-banner]').each(function (i, el) {
    var children = el.querySelectorAll(".banner__slider__slide");
    if (children.length < 2) $slickBanner.slick("unslick")
  });

  $slickBanner.on('afterChange', function () {
    var interval = 8000;
    $slickBanner.slick("pause");
    setTimeout(function () {
      $slickBanner.slick("play");
    }, interval);
  });

  sameHeight();
});


function sameHeight() {
  function debounce(func, delay) {
    var inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(function () { func.apply(context, args) }, delay);
    }
  }


  function updateElements() {
    console.log("called");
    var elements = document.querySelectorAll('[data-same-height]');
    elements.forEach(function (el) {
      var sameAs = el.getAttribute("data-same-height");
      var sameAsElement = document.querySelector(sameAs);
      if (!sameAs) return;
      var height = sameAsElement.getBoundingClientRect().height;
      el.style.height = height + "px";
    });
  };

  updateElements();

  ["resize", "DOMContentLoaded"].forEach(function (e) {
    window.addEventListener(e, debounce(updateElements, 200));
  });
}
